import { template as template_db10963019534e198f1eb47055436e2e } from "@ember/template-compiler";
const FKControlMenuContainer = template_db10963019534e198f1eb47055436e2e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
