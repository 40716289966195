import { template as template_4f7c1c8925864dd0af1d597afdc27f64 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4f7c1c8925864dd0af1d597afdc27f64(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
